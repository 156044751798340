import * as Types from "../../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NotificationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationWhereInput>;
  orderBy?: Types.InputMaybe<
    | Array<Types.NotificationOrderByWithRelationInput>
    | Types.NotificationOrderByWithRelationInput
  >;
  take?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: Array<{
    __typename?: "Notification";
    id: string;
    created_at: any;
    appointment?: {
      __typename?: "Appointment";
      id: string;
      start_time: any;
      appointment_services: Array<{
        __typename?: "AppointmentService";
        person_number: number;
      }>;
      location: {
        __typename?: "Location";
        id: string;
        name: string;
        cover_photo_url?: string | null;
        timezone: string;
      };
    } | null;
  }>;
};

export type NotificationFragment = {
  __typename?: "Notification";
  id: string;
  created_at: any;
  appointment?: {
    __typename?: "Appointment";
    id: string;
    start_time: any;
    appointment_services: Array<{
      __typename?: "AppointmentService";
      person_number: number;
    }>;
    location: {
      __typename?: "Location";
      id: string;
      name: string;
      cover_photo_url?: string | null;
      timezone: string;
    };
  } | null;
};

export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    created_at
    appointment {
      id
      start_time
      appointment_services {
        person_number
      }
      location {
        id
        name
        cover_photo_url
        timezone
      }
    }
  }
`;
export const NotificationsDocument = gql`
  query Notifications(
    $where: NotificationWhereInput
    $orderBy: [NotificationOrderByWithRelationInput!]
    $take: Int
  ) {
    notifications(where: $where, orderBy: $orderBy, take: $take) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
