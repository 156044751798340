import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AppointmentQueryVariables = Types.Exact<{
  where: Types.AppointmentWhereUniqueInput;
}>;

export type AppointmentQuery = {
  __typename?: "Query";
  appointment?: {
    __typename?: "Appointment";
    id: string;
    start_time: any;
    end_time: any;
    created_at: any;
    cancelled_at?: any | null;
    checked_in_at?: any | null;
    confirmed_at?: any | null;
    completed_at?: any | null;
    rescheduled_at?: any | null;
    user_id: string;
    full_amount?: number | null;
    appointment_services: Array<{
      __typename?: "AppointmentService";
      id: string;
      person_number: number;
      person_name?: string | null;
      user_id?: string | null;
      service: {
        __typename?: "Service";
        id: string;
        name: string;
        price: number;
        category: Types.ServiceCategory;
        add_ons: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          add_on: {
            __typename?: "Service";
            id: string;
            name: string;
            price: number;
          };
        }>;
        core_services: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          core_service: {
            __typename?: "Service";
            id: string;
            name: string;
            price: number;
          };
        }>;
      };
      add_ons: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        service_id: string;
        add_on_service_id: string;
        core_service: {
          __typename?: "Service";
          id: string;
          name: string;
          price: number;
          category: Types.ServiceCategory;
        };
      }>;
      artist: {
        __typename?: "Artist";
        id: string;
        cover_photo_url?: string | null;
        user?: {
          __typename?: "User";
          first_name?: string | null;
          photo_url?: string | null;
        } | null;
      };
    }>;
    location: {
      __typename?: "Location";
      id: string;
      slug?: string | null;
      name: string;
      timezone: string;
      address_display?: string | null;
      address_latitude?: number | null;
      address_longitude?: number | null;
      phone_number?: string | null;
      cover_photo_url?: string | null;
      multi_person?: number | null;
      menu?: {
        __typename?: "Menu";
        id: string;
        menu_sections: Array<{
          __typename?: "MenuSection";
          id: string;
          menu_section_services: Array<{
            __typename?: "MenuSectionService";
            id: string;
            service_id?: string | null;
            service?: { __typename?: "Service"; id: string } | null;
          }>;
        }>;
      } | null;
      company: {
        __typename?: "Company";
        slug?: string | null;
        payment_type: Types.PaymentType;
        late_cancellation_hours: number;
        late_cancellation_charge: number;
        user_pays_fees: boolean;
        no_show_charge: number;
      };
    };
    Order?: {
      __typename?: "Order";
      id: string;
      payment_status: Types.PaymentStatus;
      tx_cancellation_policy?: {
        __typename?: "TxCancellationPolicy";
        late_cancellation_hours: number;
        late_cancellation_charge: number;
        no_show_charge: number;
        payment_type: Types.PaymentType;
      } | null;
    } | null;
  } | null;
};

export type BookingDetailsPageAppointmentFragment = {
  __typename?: "Appointment";
  id: string;
  start_time: any;
  end_time: any;
  created_at: any;
  cancelled_at?: any | null;
  checked_in_at?: any | null;
  confirmed_at?: any | null;
  completed_at?: any | null;
  rescheduled_at?: any | null;
  user_id: string;
  full_amount?: number | null;
  appointment_services: Array<{
    __typename?: "AppointmentService";
    id: string;
    person_number: number;
    person_name?: string | null;
    user_id?: string | null;
    service: {
      __typename?: "Service";
      id: string;
      name: string;
      price: number;
      category: Types.ServiceCategory;
      add_ons: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        add_on: {
          __typename?: "Service";
          id: string;
          name: string;
          price: number;
        };
      }>;
      core_services: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        core_service: {
          __typename?: "Service";
          id: string;
          name: string;
          price: number;
        };
      }>;
    };
    add_ons: Array<{
      __typename?: "ServiceAddOn";
      id: string;
      service_id: string;
      add_on_service_id: string;
      core_service: {
        __typename?: "Service";
        id: string;
        name: string;
        price: number;
        category: Types.ServiceCategory;
      };
    }>;
    artist: {
      __typename?: "Artist";
      id: string;
      cover_photo_url?: string | null;
      user?: {
        __typename?: "User";
        first_name?: string | null;
        photo_url?: string | null;
      } | null;
    };
  }>;
  location: {
    __typename?: "Location";
    id: string;
    slug?: string | null;
    name: string;
    timezone: string;
    address_display?: string | null;
    address_latitude?: number | null;
    address_longitude?: number | null;
    phone_number?: string | null;
    cover_photo_url?: string | null;
    multi_person?: number | null;
    menu?: {
      __typename?: "Menu";
      id: string;
      menu_sections: Array<{
        __typename?: "MenuSection";
        id: string;
        menu_section_services: Array<{
          __typename?: "MenuSectionService";
          id: string;
          service_id?: string | null;
          service?: { __typename?: "Service"; id: string } | null;
        }>;
      }>;
    } | null;
    company: {
      __typename?: "Company";
      slug?: string | null;
      payment_type: Types.PaymentType;
      late_cancellation_hours: number;
      late_cancellation_charge: number;
      user_pays_fees: boolean;
      no_show_charge: number;
    };
  };
  Order?: {
    __typename?: "Order";
    id: string;
    payment_status: Types.PaymentStatus;
    tx_cancellation_policy?: {
      __typename?: "TxCancellationPolicy";
      late_cancellation_hours: number;
      late_cancellation_charge: number;
      no_show_charge: number;
      payment_type: Types.PaymentType;
    } | null;
  } | null;
};

export type BookingDetailsPageAppointmentServiceFragment = {
  __typename?: "AppointmentService";
  id: string;
  person_number: number;
  person_name?: string | null;
  user_id?: string | null;
  service: {
    __typename?: "Service";
    id: string;
    name: string;
    price: number;
    category: Types.ServiceCategory;
    add_ons: Array<{
      __typename?: "ServiceAddOn";
      id: string;
      add_on: {
        __typename?: "Service";
        id: string;
        name: string;
        price: number;
      };
    }>;
    core_services: Array<{
      __typename?: "ServiceAddOn";
      id: string;
      core_service: {
        __typename?: "Service";
        id: string;
        name: string;
        price: number;
      };
    }>;
  };
  add_ons: Array<{
    __typename?: "ServiceAddOn";
    id: string;
    service_id: string;
    add_on_service_id: string;
    core_service: {
      __typename?: "Service";
      id: string;
      name: string;
      price: number;
      category: Types.ServiceCategory;
    };
  }>;
  artist: {
    __typename?: "Artist";
    id: string;
    cover_photo_url?: string | null;
    user?: {
      __typename?: "User";
      first_name?: string | null;
      photo_url?: string | null;
    } | null;
  };
};

export type UpdateOrderMutationVariables = Types.Exact<{
  where: Types.OrderWhereUniqueInput;
  data: Types.OrderUpdateInput;
}>;

export type UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOneOrder?: { __typename?: "Order"; id: string } | null;
};

export type UpdateOneAppointmentMutationVariables = Types.Exact<{
  where: Types.AppointmentWhereUniqueInput;
  data: Types.AppointmentUpdateInput;
}>;

export type UpdateOneAppointmentMutation = {
  __typename?: "Mutation";
  updateOneAppointment?: {
    __typename?: "Appointment";
    id: string;
    start_time: any;
    end_time: any;
    created_at: any;
    cancelled_at?: any | null;
    checked_in_at?: any | null;
    confirmed_at?: any | null;
    completed_at?: any | null;
    rescheduled_at?: any | null;
    user_id: string;
    full_amount?: number | null;
    appointment_services: Array<{
      __typename?: "AppointmentService";
      id: string;
      person_number: number;
      person_name?: string | null;
      user_id?: string | null;
      service: {
        __typename?: "Service";
        id: string;
        name: string;
        price: number;
        category: Types.ServiceCategory;
        add_ons: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          add_on: {
            __typename?: "Service";
            id: string;
            name: string;
            price: number;
          };
        }>;
        core_services: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          core_service: {
            __typename?: "Service";
            id: string;
            name: string;
            price: number;
          };
        }>;
      };
      add_ons: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        service_id: string;
        add_on_service_id: string;
        core_service: {
          __typename?: "Service";
          id: string;
          name: string;
          price: number;
          category: Types.ServiceCategory;
        };
      }>;
      artist: {
        __typename?: "Artist";
        id: string;
        cover_photo_url?: string | null;
        user?: {
          __typename?: "User";
          first_name?: string | null;
          photo_url?: string | null;
        } | null;
      };
    }>;
    location: {
      __typename?: "Location";
      id: string;
      slug?: string | null;
      name: string;
      timezone: string;
      address_display?: string | null;
      address_latitude?: number | null;
      address_longitude?: number | null;
      phone_number?: string | null;
      cover_photo_url?: string | null;
      multi_person?: number | null;
      menu?: {
        __typename?: "Menu";
        id: string;
        menu_sections: Array<{
          __typename?: "MenuSection";
          id: string;
          menu_section_services: Array<{
            __typename?: "MenuSectionService";
            id: string;
            service_id?: string | null;
            service?: { __typename?: "Service"; id: string } | null;
          }>;
        }>;
      } | null;
      company: {
        __typename?: "Company";
        slug?: string | null;
        payment_type: Types.PaymentType;
        late_cancellation_hours: number;
        late_cancellation_charge: number;
        user_pays_fees: boolean;
        no_show_charge: number;
      };
    };
    Order?: {
      __typename?: "Order";
      id: string;
      payment_status: Types.PaymentStatus;
      tx_cancellation_policy?: {
        __typename?: "TxCancellationPolicy";
        late_cancellation_hours: number;
        late_cancellation_charge: number;
        no_show_charge: number;
        payment_type: Types.PaymentType;
      } | null;
    } | null;
  } | null;
};

export const BookingDetailsPageAppointmentServiceFragmentDoc = gql`
  fragment BookingDetailsPageAppointmentService on AppointmentService {
    id
    person_number
    person_name
    user_id
    service {
      id
      name
      price
      category
      add_ons {
        id
        add_on {
          id
          name
          price
        }
      }
      core_services {
        id
        core_service {
          id
          name
          price
        }
      }
    }
    add_ons {
      id
      service_id
      add_on_service_id
      core_service {
        id
        name
        price
        category
      }
    }
    artist {
      id
      cover_photo_url
      user {
        first_name
        photo_url
      }
    }
  }
`;
export const BookingDetailsPageAppointmentFragmentDoc = gql`
  fragment BookingDetailsPageAppointment on Appointment {
    id
    start_time
    end_time
    created_at
    cancelled_at
    checked_in_at
    confirmed_at
    completed_at
    rescheduled_at
    user_id
    full_amount
    appointment_services {
      ...BookingDetailsPageAppointmentService
    }
    location {
      id
      slug
      name
      timezone
      address_display
      address_latitude
      address_longitude
      phone_number
      cover_photo_url
      multi_person
      menu {
        id
        menu_sections {
          id
          menu_section_services {
            id
            service_id
            service {
              id
            }
          }
        }
      }
      company {
        slug
        payment_type
        late_cancellation_hours
        late_cancellation_charge
        user_pays_fees
        no_show_charge
      }
    }
    Order {
      id
      payment_status
      tx_cancellation_policy {
        late_cancellation_hours
        late_cancellation_charge
        no_show_charge
        payment_type
      }
    }
  }
  ${BookingDetailsPageAppointmentServiceFragmentDoc}
`;
export const AppointmentDocument = gql`
  query Appointment($where: AppointmentWhereUniqueInput!) {
    appointment(where: $where) {
      ...BookingDetailsPageAppointment
    }
  }
  ${BookingDetailsPageAppointmentFragmentDoc}
`;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentQuery,
    AppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(
    AppointmentDocument,
    options
  );
}
export function useAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentQuery,
    AppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(
    AppointmentDocument,
    options
  );
}
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<
  typeof useAppointmentLazyQuery
>;
export type AppointmentQueryResult = Apollo.QueryResult<
  AppointmentQuery,
  AppointmentQueryVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder(
    $where: OrderWhereUniqueInput!
    $data: OrderUpdateInput!
  ) {
    updateOneOrder(where: $where, data: $data) {
      id
    }
  }
`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options
  );
}
export type UpdateOrderMutationHookResult = ReturnType<
  typeof useUpdateOrderMutation
>;
export type UpdateOrderMutationResult =
  Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const UpdateOneAppointmentDocument = gql`
  mutation updateOneAppointment(
    $where: AppointmentWhereUniqueInput!
    $data: AppointmentUpdateInput!
  ) {
    updateOneAppointment(where: $where, data: $data) {
      ...BookingDetailsPageAppointment
    }
  }
  ${BookingDetailsPageAppointmentFragmentDoc}
`;
export type UpdateOneAppointmentMutationFn = Apollo.MutationFunction<
  UpdateOneAppointmentMutation,
  UpdateOneAppointmentMutationVariables
>;

/**
 * __useUpdateOneAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAppointmentMutation, { data, loading, error }] = useUpdateOneAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneAppointmentMutation,
    UpdateOneAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneAppointmentMutation,
    UpdateOneAppointmentMutationVariables
  >(UpdateOneAppointmentDocument, options);
}
export type UpdateOneAppointmentMutationHookResult = ReturnType<
  typeof useUpdateOneAppointmentMutation
>;
export type UpdateOneAppointmentMutationResult =
  Apollo.MutationResult<UpdateOneAppointmentMutation>;
export type UpdateOneAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneAppointmentMutation,
  UpdateOneAppointmentMutationVariables
>;
