import React, { useEffect, useState } from "react";
import "./CancelConfirmationModal.scss";
import Modal from "../modal/Modal";
import { TSlidingModalAlign } from "../sliding-modal/SlidingModal";
import { Button, IButtonProps } from "../../../elements/button/Button";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";
import { bemElement } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import { AxiosResponse } from "axios";
import { useAxios } from "providers/axios";

const baseClassName = "confirmation-modal";
const bem = bemElement(baseClassName);

interface ICancelConfirmationModalData {
  title?: string;
  text?: string;
  show: boolean;
  onHide: () => void;
  disableHeader?: boolean;
  disableBackground?: boolean;
  slideTo?: number;
  align?: TSlidingModalAlign;
  rightButton?: IButtonProps;
  onConfirm?: () => void;
  onReject?: () => void;
  className?: string;
  cyId?: string;
  cancelModalAppointmentId: string | null;
  setCancelModalAppointmentId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

interface Policy {
  late_cancellation_hours: number;
  late_cancellation_charge: number;
  no_show_charge: number;
}

interface Response {
  cancellation_type:
    | "lateFeeCancellation"
    | "noShowFeeCancellation"
    | "creditsGivenCancellation"
    | "noChargeNoCreditsCancellation";
  cancellation_result: CancellationResult;
  policy: Policy;
}

interface CancellationResult {
  cancellation_window: "late" | "no-show" | "none";
  cancellation_fee: number;
  total_fee?: number;
  total_credits?: number;
  amount_captured: number;
  total_fee_with_stripe_fees?: number;
}

const CancelConfirmationModal = ({
  title,
  text = "Are you sure?",
  show,
  onHide,
  disableHeader,
  disableBackground,
  slideTo,
  align = "bottom",
  rightButton,
  onConfirm,
  onReject,
  className = "",
  cyId = "",
  cancelModalAppointmentId,
  setCancelModalAppointmentId
}: ICancelConfirmationModalData) => {
  const { api } = useAxios();
  const [cancellationData, setCancellationData] = useState<Response | null>(
    null
  );

  const getCancellationData = async () => {
    const res: AxiosResponse<Response> = await api.post(
      `${process.env.REACT_APP_API_CORE_URL}/v1/cancellation-policy`,
      {
        appointment_id: cancelModalAppointmentId
      }
    );
    setCancellationData(res.data);
  };

  useEffect(() => {
    getCancellationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = cancellationData?.cancellation_result;
  const isPaidByCustomer =
    result?.amount_captured !== undefined && result.amount_captured > 0;
  const isLateOrNoShowWindow =
    result?.cancellation_window === "late" ||
    result?.cancellation_window === "no-show";
  const isLateOrNoShowType =
    cancellationData?.cancellation_type === "lateFeeCancellation" ||
    cancellationData?.cancellation_type === "noShowFeeCancellation";

  const BottomControls = () => {
    return (
      <div className={bem("buttons-wrapper")}>
        <Button
          color={COLORS.ERROR_CONTAINER}
          variant={VARIANTS.FILLED}
          text="Reject"
          onClick={onReject}
          className="flexible flex-1"
        />
        <Button
          color={COLORS.SUCCESS}
          variant={VARIANTS.FILLED}
          text="Confirm"
          cyId={cyId}
          onClick={onConfirm}
          className="flexible flex-2"
        />
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
      disableHeader={disableHeader}
      disableBackground={disableBackground}
      slideTo={slideTo}
      align={align}
      rightButton={rightButton}
      BottomControls={BottomControls}
      className={joinClassNames(baseClassName, className)}
      backgroundClassName={bem("background")}
    >
      <div className={bem("text")}>
        {isPaidByCustomer &&
          (!isLateOrNoShowWindow || !result?.cancellation_fee) && (
            <span>
              {" "}
              This will give you ${result.amount_captured} in store-credits.
            </span>
          )}
        {isLateOrNoShowWindow && !isPaidByCustomer && (
          <span> You have not paid yet.</span>
        )}
        {isLateOrNoShowWindow && isPaidByCustomer && (
          <span> You have already paid ${result.amount_captured}.</span>
        )}

        {isLateOrNoShowType &&
          result?.cancellation_fee &&
          result?.total_fee_with_stripe_fees && (
            <span>
              {" "}
              After applying a {result?.cancellation_window} cancellation fee of
              ${result.cancellation_fee}, you will incur $
              {result.total_fee_with_stripe_fees} charge.
            </span>
          )}
        {isLateOrNoShowWindow &&
          cancellationData?.cancellation_type === "creditsGivenCancellation" &&
          result?.cancellation_fee &&
          result?.total_credits && (
            <span>
              {" "}
              After applying a {result?.cancellation_window} cancellation fee of
              ${result.cancellation_fee}, you will be given $
              {result.total_credits} in store credits.
            </span>
          )}
        <span> Are you sure you want to cancel?</span>
      </div>
    </Modal>
  );
};

export default CancelConfirmationModal;
