/* eslint-disable max-len */
import "./PointmentDetails.scss";
import { useMemo } from "react";
import { bemElement } from "../../utils/bem-class-names";
import {
  TAppointment,
  TAppointmentService,
  TCalculateAppointment,
  TServiceAddOn
} from "../../types";
import { Avatar } from "../../elements/avatar/Avatar";
import { SvgIcon } from "../../elements/svg-icon/svg-icon";
import { useUser } from "../../providers/user";
import { WavyLine } from "../../elements/wavy-line/wavy-line";
import { joinClassNames } from "../../utils/join-class-names";
import { usePeopleCount } from "../../hooks/use-people-count";
import { getAvailablePaymentType } from "../../routes/booking-details/BookingDetails";

const baseClassName = "pointment-details";
const bem = bemElement(baseClassName);

const translations = {
  person: "Person",
  artist: "Artist",
  you: "you",
  paidBy: "Paid by",
  payInPerson: "Pay in Person",
  payInPersonCaption:
    "Pay at the time of your appointment by: Cash, Credit Card, or Apple Pay",
  subtotal: "Subtotal",
  tax: "Tax",
  discount: "Discount",
  totalPrice: "Total price",
  tax_and_fees: "Tax & Fees"
};

interface IPointmentDetailsData {
  appointment: TAppointment;
  className?: string;
  calculateAppointment?: TCalculateAppointment;
}

const PointmentDetails = ({
  appointment,
  calculateAppointment,
  className = ""
}: IPointmentDetailsData) => {
  const { user } = useUser();
  const peopleCount = usePeopleCount(appointment);

  const subtotal: number = useMemo(
    () =>
      appointment.appointment_services
        ?.map((appointmentService: TAppointmentService) => {
          let price: number = appointmentService?.service?.price || 0;
          price +=
            appointmentService?.service?.add_ons
              ?.map((addOn: TServiceAddOn) => addOn?.add_on?.price)
              .reduce(
                (prev: number | undefined, curr: number | undefined) =>
                  prev && curr ? prev + curr : 0,
                0
              ) || 0;
          price +=
            appointmentService?.service?.core_services
              ?.map(
                (coreServices: TServiceAddOn) =>
                  coreServices?.core_service?.price
              )
              .reduce(
                (prev: number | undefined, curr: number | undefined) =>
                  prev && curr ? prev + curr : 0,
                0
              ) || 0;

          return price;
        })
        .reduce((prev, curr) => prev + curr, 0) || 0,
    [appointment.appointment_services]
  );

  const userPaysFees = appointment?.location?.company?.user_pays_fees;
  const totalPrice = useMemo(() => {
    let totalAmount = appointment?.full_amount || subtotal;
    if (userPaysFees) {
      totalAmount += totalAmount * 0.04;
    }
    return totalAmount;
  }, [appointment?.full_amount, subtotal, userPaysFees]);

  if (!appointment || !subtotal) {
    return null;
  }

  return (
    <div className={joinClassNames(baseClassName, className)}>
      <div>
        {appointment.appointment_services?.map(
          (appointmentService: TAppointmentService) => (
            <div className={bem("order")} key={appointmentService.id}>
              <div className={bem("order-left-side")}>
                <Avatar
                  src={
                    appointmentService.person_number === 0
                      ? user.photo_url || ""
                      : ""
                  }
                  monogram={
                    appointmentService.person_number === 0
                      ? user.first_name || ""
                      : `P${(appointmentService.person_number + 1)?.toString()}`
                  }
                  monogramLength={
                    appointmentService.person_number === 0 ? 1 : 2
                  }
                  size="sm"
                  className={bem("order-left-side-avatar")}
                />
                <Avatar
                  src={
                    appointmentService?.artist?.cover_photo_url ||
                    appointmentService.artist?.user?.photo_url ||
                    ""
                  }
                  monogram={appointmentService?.artist?.user?.first_name || "A"}
                  size="sm"
                  className={bem("order-left-side-avatar")}
                />
              </div>
              <div className={bem("order-right-side")}>
                <div className={bem("order-right-side-top")}>
                  <div>
                    <div className={bem("order-right-side-caption")}>
                      {translations.person}{" "}
                      {appointmentService.person_number + 1}
                      {appointmentService.person_number === 0
                        ? ` (${translations.you})`
                        : ""}
                    </div>
                    <div className={bem("order-right-side-name")}>
                      {appointmentService.person_number === 0
                        ? user.first_name
                        : `${translations.person} ${peopleCount}`}
                    </div>
                  </div>
                  <SvgIcon name="slash" />
                  <div>
                    <div className={bem("order-right-side-caption")}>
                      {translations.artist}
                    </div>
                    <div className={bem("order-right-side-name")}>
                      {appointmentService?.artist?.user?.first_name}
                    </div>
                  </div>
                </div>
                <div className={bem("order-right-side-services")}>
                  <div
                    className={joinClassNames(
                      bem("price-row"),
                      "font-semibold"
                    )}
                  >
                    <div>{appointmentService?.service?.name}</div>
                    <div>${appointmentService?.service?.price}</div>
                  </div>
                  <div>
                    {appointmentService?.add_ons &&
                      appointmentService?.add_ons?.length > 0 &&
                      appointmentService?.add_ons.map(
                        (addOn: TServiceAddOn) => (
                          <div className={bem("price-row")}>
                            <div>{addOn?.core_service?.name}</div>
                            <div>
                              +$
                              {addOn?.core_service?.price !== 0
                                ? addOn?.core_service?.price
                                : "Free"}
                            </div>
                          </div>
                        )
                      )}
                    {/* {appointmentService?.service?.add_ons &&
                    appointmentService?.service?.add_ons?.length > 0
                      ? ","
                      : ""} */}
                    {appointmentService?.service?.core_services &&
                      appointmentService?.service?.core_services?.length > 0 &&
                      appointmentService.service.core_services.map(
                        (coreService: TServiceAddOn) => (
                          <div className={bem("price-row")}>
                            <div>Ritual({coreService?.core_service?.name})</div>
                            <div>
                              {coreService?.core_service?.price !== 0
                                ? `+$${coreService?.core_service?.price}`
                                : "Free"}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className={bem("summary")}>
        <div className={bem("price-row")}>
          <div>{translations.subtotal}</div>
          <div>${subtotal.toFixed(2)}</div>
        </div>
        <div className={bem("price-row")}>
          <div>{translations.tax_and_fees}</div>
          <div>+${userPaysFees ? (subtotal * 0.04).toFixed(2) : 0}</div>
        </div>
        {/*TODO*/}
        {/*<div className={bem("price-row")}>*/}
        {/*  <div>*/}
        {/*    {translations.discount} ({appointment?.full_amount ? subtotal / appointment?.full_amount : 0}%)*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    -${appointment?.full_amount ? appointment.full_amount - subtotal : 0}*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={joinClassNames(bem("price-row"), "font-semibold")}>
          <div>{translations.totalPrice}</div>
          <div>${totalPrice.toFixed(2)}</div>
        </div>
      </div>
      <WavyLine />
      <div className={bem("payment")}>
        <div className={bem("icon-wrapper")}>
          <div className={bem("options-container")}>
            {getAvailablePaymentType(
              appointment.Order?.tx_cancellation_policy
                ?.late_cancellation_charge ||
                appointment.location?.company?.late_cancellation_charge,
              calculateAppointment
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointmentDetails;
