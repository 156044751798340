import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FavoriteArtistsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.FavoriteArtistWhereInput>;
}>;

export type FavoriteArtistsQuery = {
  __typename?: "Query";
  favoriteArtists: Array<{
    __typename?: "FavoriteArtist";
    id: string;
    artist: {
      __typename?: "Artist";
      id: string;
      cover_photo_url?: string | null;
      availabilities: Array<{
        __typename?: "ArtistAvailability";
        availability: {
          __typename?: "Availability";
          status: string;
          start_time: any;
        };
      }>;
      user?: {
        __typename?: "User";
        first_name?: string | null;
        photo_url?: string | null;
      } | null;
    };
  }>;
};

export type FavoriteArtistFragment = {
  __typename?: "FavoriteArtist";
  id: string;
  artist: {
    __typename?: "Artist";
    id: string;
    cover_photo_url?: string | null;
    availabilities: Array<{
      __typename?: "ArtistAvailability";
      availability: {
        __typename?: "Availability";
        status: string;
        start_time: any;
      };
    }>;
    user?: {
      __typename?: "User";
      first_name?: string | null;
      photo_url?: string | null;
    } | null;
  };
};

export type FavoriteLocationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFavoriteLocationWhereInput>;
}>;

export type FavoriteLocationsQuery = {
  __typename?: "Query";
  userFavoriteLocations: Array<{
    __typename?: "UserFavoriteLocation";
    id: string;
    location: {
      __typename?: "Location";
      id: string;
      company_id: string;
      cover_photo_url?: string | null;
      name: string;
      address_display?: string | null;
      slug?: string | null;
    };
  }>;
};

export type FavoriteLocationFragment = {
  __typename?: "UserFavoriteLocation";
  id: string;
  location: {
    __typename?: "Location";
    id: string;
    company_id: string;
    cover_photo_url?: string | null;
    name: string;
    address_display?: string | null;
    slug?: string | null;
  };
};

export type AppointmentsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AppointmentWhereInput>;
}>;

export type AppointmentsQuery = {
  __typename?: "Query";
  appointments: Array<{
    __typename?: "Appointment";
    id: string;
    start_time: any;
    end_time: any;
    cancelled_at?: any | null;
    appointment_services: Array<{
      __typename?: "AppointmentService";
      person_number: number;
    }>;
    location: {
      __typename?: "Location";
      id: string;
      slug?: string | null;
      name: string;
      timezone: string;
      address_display?: string | null;
      address_latitude?: number | null;
      address_longitude?: number | null;
      cover_photo_url?: string | null;
    };
  }>;
};

export type BookedAppointmentFragment = {
  __typename?: "Appointment";
  id: string;
  start_time: any;
  end_time: any;
  cancelled_at?: any | null;
  appointment_services: Array<{
    __typename?: "AppointmentService";
    person_number: number;
  }>;
  location: {
    __typename?: "Location";
    id: string;
    slug?: string | null;
    name: string;
    timezone: string;
    address_display?: string | null;
    address_latitude?: number | null;
    address_longitude?: number | null;
    cover_photo_url?: string | null;
  };
};

export const FavoriteArtistFragmentDoc = gql`
  fragment FavoriteArtist on FavoriteArtist {
    id
    artist {
      id
      cover_photo_url
      availabilities {
        availability {
          status
          start_time
        }
      }
      user {
        first_name
        photo_url
      }
    }
  }
`;
export const FavoriteLocationFragmentDoc = gql`
  fragment FavoriteLocation on UserFavoriteLocation {
    id
    location {
      id
      company_id
      cover_photo_url
      name
      address_display
      slug
    }
  }
`;
export const BookedAppointmentFragmentDoc = gql`
  fragment BookedAppointment on Appointment {
    id
    start_time
    end_time
    cancelled_at
    appointment_services {
      person_number
    }
    location {
      id
      slug
      name
      timezone
      address_display
      address_latitude
      address_longitude
      cover_photo_url
    }
  }
`;
export const FavoriteArtistsDocument = gql`
  query FavoriteArtists($where: FavoriteArtistWhereInput) {
    favoriteArtists(where: $where) {
      ...FavoriteArtist
    }
  }
  ${FavoriteArtistFragmentDoc}
`;

/**
 * __useFavoriteArtistsQuery__
 *
 * To run a query within a React component, call `useFavoriteArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteArtistsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFavoriteArtistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FavoriteArtistsQuery,
    FavoriteArtistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteArtistsQuery, FavoriteArtistsQueryVariables>(
    FavoriteArtistsDocument,
    options
  );
}
export function useFavoriteArtistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FavoriteArtistsQuery,
    FavoriteArtistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FavoriteArtistsQuery,
    FavoriteArtistsQueryVariables
  >(FavoriteArtistsDocument, options);
}
export type FavoriteArtistsQueryHookResult = ReturnType<
  typeof useFavoriteArtistsQuery
>;
export type FavoriteArtistsLazyQueryHookResult = ReturnType<
  typeof useFavoriteArtistsLazyQuery
>;
export type FavoriteArtistsQueryResult = Apollo.QueryResult<
  FavoriteArtistsQuery,
  FavoriteArtistsQueryVariables
>;
export const FavoriteLocationsDocument = gql`
  query FavoriteLocations($where: UserFavoriteLocationWhereInput) {
    userFavoriteLocations(where: $where) {
      ...FavoriteLocation
    }
  }
  ${FavoriteLocationFragmentDoc}
`;

/**
 * __useFavoriteLocationsQuery__
 *
 * To run a query within a React component, call `useFavoriteLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFavoriteLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FavoriteLocationsQuery,
    FavoriteLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FavoriteLocationsQuery,
    FavoriteLocationsQueryVariables
  >(FavoriteLocationsDocument, options);
}
export function useFavoriteLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FavoriteLocationsQuery,
    FavoriteLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FavoriteLocationsQuery,
    FavoriteLocationsQueryVariables
  >(FavoriteLocationsDocument, options);
}
export type FavoriteLocationsQueryHookResult = ReturnType<
  typeof useFavoriteLocationsQuery
>;
export type FavoriteLocationsLazyQueryHookResult = ReturnType<
  typeof useFavoriteLocationsLazyQuery
>;
export type FavoriteLocationsQueryResult = Apollo.QueryResult<
  FavoriteLocationsQuery,
  FavoriteLocationsQueryVariables
>;
export const AppointmentsDocument = gql`
  query Appointments($where: AppointmentWhereInput) {
    appointments(where: $where, orderBy: { start_time: asc }) {
      ...BookedAppointment
    }
  }
  ${BookedAppointmentFragmentDoc}
`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AppointmentsQuery,
    AppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(
    AppointmentsDocument,
    options
  );
}
export function useAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsQuery,
    AppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(
    AppointmentsDocument,
    options
  );
}
export type AppointmentsQueryHookResult = ReturnType<
  typeof useAppointmentsQuery
>;
export type AppointmentsLazyQueryHookResult = ReturnType<
  typeof useAppointmentsLazyQuery
>;
export type AppointmentsQueryResult = Apollo.QueryResult<
  AppointmentsQuery,
  AppointmentsQueryVariables
>;
