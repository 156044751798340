/* eslint-disable max-len */
import "./BookingDetails.scss";
import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  BookingDetailsPageAppointmentFragment,
  BookingDetailsPageAppointmentServiceFragment,
  useAppointmentQuery,
  useUpdateOneAppointmentMutation,
  useUpdateOrderMutation
} from "./BookingDetails.generated";
import { ImageElement } from "../../elements/image/Image";
import { SvgIcon } from "../../elements/svg-icon/svg-icon";
import {
  getDate,
  getFormattedTime,
  getRelativeDate,
  getTime,
  getZonedDate
} from "../../utils/date-time";
import { bemElement, bemModifier } from "../../utils/bem-class-names";
import { ListItem } from "../../components/list-item/ListItem";
import { useUser } from "../../providers/user";
import { WavyLine } from "../../elements/wavy-line/wavy-line";
import {
  TAppointment,
  TCalculateAppointment,
  TLocation,
  TService,
  TUser
} from "../../types";
import { Avatar } from "../../elements/avatar/Avatar";
import { Button } from "../../elements/button/Button";
import { COLORS } from "../../models/colors";
import { VARIANTS } from "../../models/variants";
import { getRefundAndCancellationHtml } from "../../utils/get-refund-and-cancellation-html";
import FormattedTextModal from "../../components/modals/formatted-text-modal/FormattedTextModal";
import { IconButton } from "../../elements/icon-button/IconButton";
import PointmentDetailsModal from "../../components/modals/pointment-details-modal/PointmentDetailsModal";
import TwoColsLayout from "../../components/layout/two-cols-layout/TwoColsLayout";
import PointmentDetails from "../../components/pointment-details/PointmentDetails";
import { Desktop, Mobile } from "../../components/responsive/Responsive";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import useResponsive from "../../hooks/useResponsive";
import { useCart } from "../../providers/cart-provider";
import DateAndTime, {
  IModalError
} from "../../components/date-and-time/DateAndTime";
import { usePeopleCount } from "../../hooks/use-people-count";
import { AddToCalendarModal } from "../../components/modals/add-to-calendar-modal/AddToCalendarModal";
import { APP_ROUTES } from "../../routes";
import { useAxios } from "../../providers/axios";
import { Toast } from "../../elements/toast/Toast";
import { PAYMENT_TYPES } from "../../models/payment-types";
import { HEADER_TYPE, useHeader } from "../../providers/header-provider";
import { useHeaderTransparent } from "../../components/header/Header";
import Modal from "components/modals/modal/Modal";
import CancelConfirmationModal from "components/modals/cancel-confirmation-modal/CancelConfirmationModal";
import { getPhone } from "../../utils/getPhone";
import { getRawPhoneNumber } from "../../utils/getRawPhoneNumber";

const baseClassName = "booking-details-page";
const bem = bemElement(baseClassName);

const translations = {
  directionLink: "Get directions",
  locationDetails: "Location details & Menu",
  pointmentDetails: "Pointment details",
  reschedule: "Reschedule the Pointment",
  cancel: "Cancel the Pointment",
  addToCalendar: "Add to calendar",
  person: "Person",
  artist: "Artist",
  you: "you",
  totalPrice: "Total price",
  paidBy: "Paid by",
  payInPerson: "Pay in Person",
  refundAndCancellationPolicy: "Refund and Cancellation Policy",
  refundAndCancellation: "Refund & Cancellation"
};

export const getAvailablePaymentType = (
  late_cancellation_charge?: number,
  calculateAppointment?: TCalculateAppointment | null
) => {
  if (!calculateAppointment || !late_cancellation_charge) {
    return null;
  }

  let paymentTypeData = {
    title: "Pay in full",
    description: `${
      calculateAppointment.amount_paid_now_with_refunds > 0
        ? `$${calculateAppointment.amount_paid_now_with_refunds.toFixed(
            2
          )} in credits has been applied.`
        : ""
    } Payment of $${calculateAppointment.amount_paid_with_payment_method.toFixed(
      2
    )} is already paid.`,
    iconName: "other_full_payment",
    iconPosition: "middle"
  };

  switch (calculateAppointment.payment_type) {
    case PAYMENT_TYPES.PARTIAL_PAY_UPFRONT:
      paymentTypeData = {
        title: "Partial pay upfront",
        description: `${
          calculateAppointment.amount_paid_now_with_refunds > 0
            ? `$${calculateAppointment.amount_paid_now_with_refunds.toFixed(
                2
              )} in credits has been applied.`
            : ""
        } ${
          calculateAppointment.amount_paid_with_payment_method > 0
            ? `$${calculateAppointment.amount_paid_with_payment_method.toFixed(
                2
              )} of $${calculateAppointment.total_price} is already paid.`
            : ""
        } Final payment of $${calculateAppointment.amount_paid_at_appointment.toFixed(
          2
        )} at the time of your appointment by: Cash, Credit Card, or Apple Pay.`,
        iconName: "other_partial_payment",
        iconPosition: "start"
      };
      break;
    case PAYMENT_TYPES.PAY_IN_STORE:
      paymentTypeData = {
        title: "Pay in store",
        description: `Final payment of $${calculateAppointment.total_price.toFixed(
          2
        )} at the time of your appointment by: Cash, Credit Card, or Apple Pay.  ${
          late_cancellation_charge > 0
            ? `Late cancellation fee of $${(
                late_cancellation_charge *
                (calculateAppointment.total_price || 0)
              ).toFixed(2)} would apply.`
            : ""
        }`,
        iconName: "other_store",
        iconPosition: "start"
      };
      break;
  }

  return (
    <div
      className={bemModifier(bem("payment-type"), paymentTypeData.iconPosition)}
    >
      <div className={bem("payment-type-left")}>
        <SvgIcon name={paymentTypeData.iconName} />
      </div>
      <div className={bem("payment-type-right")}>
        <span>{paymentTypeData.title}</span>
        <span>{paymentTypeData.description}</span>
      </div>
    </div>
  );
};

type TAppointmentStatus = "upcoming" | "recent" | "cancelled";

export const getAppointmentStatus = (
  appointment: BookingDetailsPageAppointmentFragment
): TAppointmentStatus => {
  if (appointment.cancelled_at || appointment.rescheduled_at) {
    return "cancelled";
  }
  if (
    getZonedDate(appointment.start_time, appointment.location.timezone) >
    getZonedDate(new Date().toISOString(), appointment.location.timezone)
  ) {
    return "upcoming";
  } else {
    return "recent";
  }
};

const getTimeString = (appointment: BookingDetailsPageAppointmentFragment) => {
  let startTime: string = getRelativeDate(
    appointment.start_time,
    appointment.location.timezone
  );
  let caption: string = "";

  switch (getAppointmentStatus(appointment)) {
    case "upcoming":
      startTime += ` from ${getTime(
        appointment.start_time,
        appointment.location.timezone
      )}`;
      caption = ` ~ ${getTime(
        appointment.end_time,
        appointment.location.timezone
      )}`;
      break;
    case "recent":
      startTime += ` at ${getTime(
        appointment.start_time,
        appointment.location.timezone
      )}`;
      break;
    case "cancelled":
      startTime += ` at ${getTime(
        appointment.start_time,
        appointment.location.timezone
      )} • `;
      caption = "Cancelled";
      break;
  }

  return (
    <>
      <SvgIcon
        name={
          getAppointmentStatus(appointment) === "cancelled"
            ? "close_circle"
            : "time"
        }
      />
      <div>
        {startTime}
        <span>{caption}</span>
      </div>
    </>
  );
};

const getButtons = (
  appointment: BookingDetailsPageAppointmentFragment,
  onRescheduleClick: () => void,
  onAddToCalendarClick: () => void,
  onMissingServicesClick: (() => void) | null
) => {
  // TODO: Add to calendar onClick
  if (getAppointmentStatus(appointment) === "upcoming") {
    const currentTime = getZonedDate(
      new Date().toISOString(),
      appointment.location.timezone
    ).getTime();

    const appointmentStartTime = getZonedDate(
      appointment.start_time,
      appointment.location.timezone
    ).getTime();

    const lateCancellationHours =
      appointment.Order?.tx_cancellation_policy?.late_cancellation_hours ||
      appointment.location.company.late_cancellation_hours;

    const lateCancellationPeriod = lateCancellationHours * 60 * 60 * 1000;

    const isLateCancellation =
      currentTime >= appointmentStartTime - lateCancellationPeriod &&
      currentTime < appointmentStartTime;
    return (
      <div className="flex gap-[6px] w-100%">
        <Button
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          text="Add to calendar"
          onClick={onAddToCalendarClick}
          className="justify-center !text-[14px] !leading-4 min-w-[120px] w-100%"
        />
        {!isLateCancellation && (
          <Button
            color={COLORS.SECONDARY}
            variant={VARIANTS.FILLED}
            cyId="reschedule-appointment-button"
            text="Reschedule a Pointment"
            onClick={onRescheduleClick}
            className="justify-center !text-[14px] !leading-4 min-w-[120px] w-100%"
          />
        )}
      </div>
    );
  } else {
    return (
      <>
        {onMissingServicesClick ? (
          <Button
            color={COLORS.SECONDARY}
            variant={VARIANTS.FILLED}
            text="Re-book a Pointment"
            className="flexible w-100%"
            onClick={onMissingServicesClick}
          />
        ) : (
          <Link
            to={`/company/${appointment.location.company.slug}/location/${appointment.location.slug}`}
            className="w-full"
          >
            <Button
              color={COLORS.SECONDARY}
              variant={VARIANTS.FILLED}
              text="Re-book a Pointment"
              className="flexible w-100%"
            />
          </Link>
        )}
      </>
    );
  }
};

const getLocationInfo = (
  appointment: BookingDetailsPageAppointmentFragment,
  peopleCount: number,
  onRescheduleClick: () => void,
  onAddToCalendarClick: () => void,
  onCancelClick: () => void,
  onMissingServicesClick: (() => void) | null
) => {
  const currentTime = getZonedDate(
    new Date().toISOString(),
    appointment.location.timezone
  ).getTime();

  const appointmentStartTime = getZonedDate(
    appointment.start_time,
    appointment.location.timezone
  ).getTime();

  const lateCancellationHours =
    appointment.Order?.tx_cancellation_policy?.late_cancellation_hours ||
    appointment.location.company.late_cancellation_hours;

  const lateCancellationPeriod = lateCancellationHours * 60 * 60 * 1000;

  const isLateCancellation =
    currentTime >= appointmentStartTime - lateCancellationPeriod &&
    currentTime < appointmentStartTime;
  return (
    <div className={bem("location-info-wrapper")}>
      <div className={bem("location")}>
        <ImageElement
          alt={appointment.location.name}
          aspectRatio="16:9"
          src={appointment.location.cover_photo_url || ""}
          className={bem("location-cover-photo")}
        />
        <div className={bem("location-info")}>
          <div className={bem("location-info-name")}>
            {appointment.location.name}
          </div>
          <div className={bem("location-info-address")}>
            {appointment.location.address_display}
          </div>
        </div>
        <div className={bem("location-info-gradient")} />
        {/*/!*TODO*!/*/}
        {/*<IconButton*/}
        {/*  color={COLORS.BACKGROUND}*/}
        {/*  variant={VARIANTS.FILLED}*/}
        {/*  iconName="share"*/}
        {/*  className={bem("location-share-button")}*/}
        {/*/>*/}
      </div>
      <div
        className={bemModifier(
          bem("short-description"),
          getAppointmentStatus(appointment)
        )}
      >
        <div className={bem("short-description-row")}>
          <SvgIcon name="amenities_good_for_groups" />
          {peopleCount} {peopleCount > 1 ? "people" : "person"} •{" "}
          {appointment.appointment_services.length} service
          {appointment.appointment_services.length > 1 ? "s" : ""}
        </div>
        <div className={bem("short-description-row")}>
          {getTimeString(appointment)}
        </div>
      </div>
      <div className={bem("links")}>
        {appointment.location?.address_display && (
          <a
            href={`https://www.google.com/maps?q=${appointment.location.address_display}`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItem disableRightArrow className={bem("list-item-container")}>
              <div className={bem("list-item")}>
                <div className={bem("list-item-left-side")}>
                  {/* TODO: add  icon - arrow-outward */}
                  <SvgIcon name="temp_arrow_outward" className="mr-6px" />
                  {translations.directionLink}
                </div>
                {/* TODO: add icon - arrow_redirect */}
                <SvgIcon
                  name="temp_arrow_redirect"
                  className="fill-on-surface-variant"
                />
              </div>
            </ListItem>
          </a>
        )}
        {appointment.location?.phone_number && (
          <a
            href={`tel:${getRawPhoneNumber(appointment.location.phone_number)}`}
          >
            <ListItem disableRightArrow className={bem("list-item-container")}>
              <div className={bem("list-item")}>
                <div className={bem("list-item-left-side")}>
                  {/* TODO: add icon - call */}
                  <SvgIcon name="temp_call" className="mr-6px" />
                  {getPhone(appointment.location.phone_number)}
                </div>
                {/* TODO: add icon - arrow_redirect */}
                <SvgIcon
                  name="temp_arrow_redirect"
                  className="fill-on-surface-variant"
                />
              </div>
            </ListItem>
          </a>
        )}
        {appointment.location?.slug && (
          <Link
            to={`/company/${appointment.location.company.slug}/location/${appointment.location.slug}`}
          >
            <ListItem disableRightArrow className={bem("list-item-container")}>
              <div className={bem("list-item")}>
                <div className={bem("list-item-left-side")}>
                  <SvgIcon name="menu" className={bem("list-item-menu-icon")} />
                  {translations.locationDetails}
                </div>
                {/* TODO: add icon - arrow_redirect */}
                <SvgIcon
                  name="temp_arrow_redirect"
                  className="fill-on-surface-variant"
                />
              </div>
            </ListItem>
          </Link>
        )}
        {getAppointmentStatus(appointment) === "upcoming" && (
          <>
            {((appointment.Order?.tx_cancellation_policy
              ?.payment_type as unknown as PAYMENT_TYPES) ||
              (appointment.location.company
                .payment_type as unknown as PAYMENT_TYPES)) !==
              PAYMENT_TYPES.PARTIAL_PAY_UPFRONT &&
              !isLateCancellation && (
                <ListItem
                  className={bem("list-item-container")}
                  onClick={onRescheduleClick}
                  cyId="reschedule-appointment-button"
                >
                  <div className={bem("list-item")}>
                    <div className={bem("list-item-left-side")}>
                      <SvgIcon name="reschedule" className="mr-6px" />
                      {translations.reschedule}
                    </div>
                  </div>
                </ListItem>
              )}
            <ListItem
              className={bem("list-item-container")}
              onClick={onCancelClick}
              cyId="cancel-appointment-button"
            >
              <div className={bem("list-item")}>
                <div className={bem("list-item-left-side")}>
                  <SvgIcon name="temp_close_circle" className="mr-6px" />
                  {translations.cancel}
                </div>
              </div>
            </ListItem>
          </>
        )}
      </div>
      <div className={bem("location-info-bottom-controls")}>
        {getButtons(
          appointment,
          onRescheduleClick,
          onAddToCalendarClick,
          onMissingServicesClick
        )}
      </div>
    </div>
  );
};

const getPersonOrder = (
  appointmentService: BookingDetailsPageAppointmentServiceFragment,
  user: TUser,
  personNumber: number
) => {
  return (
    <div className={bem("details-order")} key={appointmentService.id}>
      <div className={bem("details-order-left-side")}>
        <Avatar
          src={
            appointmentService.person_number === 0 ? user.photo_url || "" : ""
          }
          monogram={
            appointmentService.person_number === 0
              ? user.first_name || ""
              : `P${(appointmentService.person_number + 1)?.toString()}`
          }
          monogramLength={appointmentService.person_number === 0 ? 1 : 2}
          size="sm"
          className={bem("details-order-left-side-avatar")}
        />
        <Avatar
          src={
            appointmentService?.artist?.cover_photo_url ||
            appointmentService.artist?.user?.photo_url ||
            ""
          }
          monogram={appointmentService?.artist?.user?.first_name || "A"}
          size="sm"
          className={bem("details-order-left-side-avatar")}
        />
      </div>
      <div className={bem("details-order-right-side")}>
        <div className={bem("details-order-right-side-top")}>
          <div>
            <div className={bem("details-order-right-side-caption")}>
              {translations.person} {personNumber}
              {appointmentService.user_id === user.id
                ? ` (${translations.you})`
                : ""}
            </div>
            <div className={bem("details-order-right-side-name")}>
              {appointmentService.user_id === user.id
                ? user.first_name
                : `${translations.person} ${personNumber}`}
            </div>
          </div>
          <SvgIcon name="slash" />
          <div>
            <div className={bem("details-order-right-side-caption")}>
              {translations.artist}
            </div>
            <div className={bem("details-order-right-side-name")}>
              {appointmentService.artist.user?.first_name}
            </div>
          </div>
        </div>
        <div className={bem("details-order-right-side-services")}>
          <div className="font-semibold">
            {appointmentService.service.name}
            {appointmentService?.add_ons?.length > 0 ? " &" : ""}
          </div>
          <div>
            {appointmentService?.add_ons?.length > 0 &&
              appointmentService?.add_ons?.map((addOn, i) => (
                <span>
                  {addOn.core_service.name}
                  {i < appointmentService?.add_ons?.length - 1 ? ", " : ""}
                </span>
              ))}
            {/* {appointmentService.service.add_ons.length > 0 ? "," : ""} */}
            {appointmentService.service.core_services.length > 0 &&
              appointmentService.service.core_services.map((coreService, i) => (
                <span>
                  Ritual({coreService.core_service.name})
                  {i !== appointmentService.service.add_ons.length ? ", " : ""}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const BookingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const { setData } = useHeader();
  const isHeaderTransparent = useHeaderTransparent();
  const { api } = useAxios();
  const {
    setCurrentLocation,
    selectServices,
    addActivePerson,
    activePersonsNumber,
    multiPerson,
    getBookAppointmentBody,
    cart
  } = useCart();
  const { isDesktop, isXS } = useResponsive();
  const [showPointmentDetailsModal, setShowPointmentDetailsModal] =
    useState<boolean>(false);
  const [cancelModalAppointmentId, setCancelModalAppointmentId] = useState<
    string | null
  >(null);
  const [calculateAppointment, setCalculateAppointment] =
    useState<TCalculateAppointment | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [showAddToCalendarModal, setShowAddToCalendarModal] =
    useState<boolean>(false);
  const [textModalData, setTextModalData] = useState<{
    title: string;
    text: string;
  }>();
  const [isRescheduling, setIsRescheduling] = useState<boolean>(false);
  const [modalError, setModalError] = useState<IModalError | null>(null);

  const { data, loading } = useAppointmentQuery({
    variables: {
      where: {
        id
      }
    }
  });

  const appointment = data?.appointment;

  const [updateAppointment] = useUpdateOneAppointmentMutation();
  const [updateOrder] = useUpdateOrderMutation();

  const peopleCount = usePeopleCount(appointment as unknown as TAppointment);

  const showRefundPolicyButton = useMemo(() => {
    const appointmentDate = getZonedDate(
      appointment?.start_time,
      appointment?.location.timezone
    );
    const now = getZonedDate(
      new Date().toISOString(),
      appointment?.location.timezone
    );

    const conditions = [
      !appointment,
      appointmentDate < now,
      appointment?.cancelled_at,
      appointment?.completed_at
    ];

    return !conditions.some((condition) => condition);
  }, [appointment]);

  useEffect(() => {
    if (user?.impersonate_name) {
      setIsRescheduling(true);
    } else {
      setIsRescheduling(false);
    }
  }, [appointment, user?.impersonate_name]);

  useEffect(() => {
    setData({
      type: HEADER_TYPE.TYPE_1,
      leftSection: !isXS ? null : (
        <IconButton
          color={COLORS.SECONDARY_CONTAINER}
          variant={isHeaderTransparent ? VARIANTS.FILLED : VARIANTS.DEFAULT}
          iconName="arrow_left"
          onClick={() => navigate(APP_ROUTES.ME)}
        />
      ),
      rightSection: !isXS || !isHeaderTransparent ? null : <></>,
      centerSection: !isXS || !isHeaderTransparent ? null : <></>
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isXS, isHeaderTransparent]);

  useEffect(() => {
    if (appointment && !loading) {
      if (!cart.location) {
        setCurrentLocation(appointment?.location as unknown as TLocation);
      }
      if (cart.location) {
        selectServices(
          appointment?.appointment_services?.map((appointmentService) => ({
            service: appointmentService.service as TService,
            personIndex: appointmentService.person_number,
            addOns: appointmentService?.add_ons?.map(
              (item) => item.core_service.id
            )
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, loading, cart.location, cart.services.length]);

  useEffect(() => {
    (async function () {
      try {
        if (!appointment) {
          return null;
        }
        const body = {
          ...getBookAppointmentBody(),
          location_id: appointment.location?.id,
          appointment_id: appointment.id
        };
        if (!body?.services?.length) {
          return null;
        }

        const response: TCalculateAppointment = (await api
          .post(`/v1/calculate-appointment/`, body)
          .then((r) => r.data)) as TCalculateAppointment;
        setCalculateAppointment(response);
      } catch (err) {
        setErrorMsg(err.response?.data.message);
      }
    })();
  }, [getBookAppointmentBody, api, appointment]);

  useEffect(() => {
    if (appointment && !loading && activePersonsNumber !== peopleCount) {
      addActivePerson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePersonsNumber, appointment, loading, peopleCount, multiPerson]);

  if (!id) {
    navigate("/me");
    return null;
  }

  if (!appointment || !peopleCount || !user || !calculateAppointment) {
    return null;
  }

  const locationServiceIds = new Set(
    cart.location?.menu?.menu_sections
      ?.flatMap((section) => section.menu_section_services)
      ?.map(
        (menuSectionService) =>
          menuSectionService?.service_id || menuSectionService?.service?.id
      )
  );

  const isAllServicesIncluded = appointment?.appointment_services?.every(
    (appointmentService) =>
      locationServiceIds.has(appointmentService.service.id)
  );

  const onCancel = () => {
    updateAppointment({
      variables: {
        where: {
          id: appointment.id
        },
        data: {
          cancelled_at: {
            set: new Date().toISOString()
          }
        }
      },
      onCompleted: () => {
        updateOrder({
          variables: {
            where: {
              id: appointment.Order?.id
            },
            data: {
              cancelled_at: {
                set: new Date().toISOString()
              }
            }
          },
          onError: (error) => {
            setErrorMsg(
              error?.message || "Failed to update order cancellation."
            );
          }
        });
        setCancelModalAppointmentId(null);
        setShowTextModal(false);
      },
      onError: (error) => {
        setErrorMsg(error?.message || "Failed to cancel appointment.");
        setCancelModalAppointmentId(null);
        setShowTextModal(false);
      }
    });
  };

  const CancelButton = () => {
    return (
      <div className={bem("cancel-button-wrapper")}>
        <Button
          color={COLORS.ERROR_CONTAINER}
          variant={VARIANTS.FILLED}
          text={translations.cancel}
          onClick={() => {
            setCancelModalAppointmentId(appointment.id);
            setShowTextModal(false);
          }}
          className="flexible w-100%"
        />
      </div>
    );
  };

  if (isRescheduling && !isDesktop) {
    return (
      <DateAndTime
        className={bem("mobile-date-and-time")}
        rescheduleAppointment={appointment}
        setModalError={setModalError}
      />
    );
  }

  const BottomControls = () => {
    const appointmentServices = appointment?.appointment_services;
    const appointmentServicesWithoutMissingServices =
      appointmentServices?.filter((appointmentService) =>
        locationServiceIds?.has(appointmentService.service.id)
      );

    return (
      <div className="flex justify-center px-4 py-2 bg-background border-t border-t-surface-variant">
        {modalError?.type === "Warning" ? (
          <Button
            color={COLORS.SUCCESS}
            variant={VARIANTS.FILLED}
            text="Ok got it"
            onClick={() => {
              selectServices(
                appointmentServicesWithoutMissingServices?.map(
                  (appointmentService) => ({
                    service: appointmentService.service as TService,
                    personIndex: appointmentService.person_number
                  })
                )
              );
              navigate(
                `/company/${appointment.location.company.slug}/location/${appointment.location.slug}`
              );
              setModalError(null);
            }}
          />
        ) : (
          <Button
            color={COLORS.SUCCESS}
            variant={VARIANTS.FILLED}
            text="Ok got it"
            onClick={() => {
              setIsRescheduling(false);
              setModalError(null);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Pointment | Booking</title>
        <meta property="og:title" content="Pointment | Booking" />
      </Helmet>
      <div className={baseClassName}>
        <TwoColsLayout className={bem("layout")} equalCols separator>
          <div>
            <Desktop>
              <Breadcrumbs
                breadcrumbs={[{ title: "Upcoming Pointment", url: "" }]}
                rootCrumbUrl={APP_ROUTES.ME}
              />
              <div className={bem("left-side-title")}>
                {getAppointmentStatus(appointment)} Pointment
              </div>
            </Desktop>
            {getLocationInfo(
              appointment,
              peopleCount,
              () => setIsRescheduling(true),
              () => setShowAddToCalendarModal(true),
              () => setCancelModalAppointmentId(appointment.id),
              isAllServicesIncluded
                ? null
                : () =>
                    setModalError({
                      type: "Warning",
                      text: "Some services from past appointment not available."
                    })
            )}
          </div>
          <div className={bem("container")}>
            <div className={bem("details")}>
              <div className={bem("details-title")}>
                {translations.pointmentDetails}
              </div>
              <Desktop>
                <Breadcrumbs
                  rootCrumbName={translations.pointmentDetails}
                  breadcrumbs={
                    isRescheduling
                      ? [{ title: translations.reschedule, url: "" }]
                      : []
                  }
                />
                {errorMsg && (
                  <Toast
                    msg={errorMsg}
                    onClose={() => setErrorMsg("")}
                    type="error"
                    showIcon
                    rounded
                  />
                )}
                {isRescheduling ? (
                  <DateAndTime
                    className={bemModifier(bem("details-block"), {
                      "date-and-time": isRescheduling
                    })}
                    onClose={() => setIsRescheduling(false)}
                    rescheduleAppointment={appointment}
                    setModalError={setModalError}
                  />
                ) : (
                  <PointmentDetails
                    appointment={appointment as unknown as TAppointment}
                    calculateAppointment={calculateAppointment}
                    className={bem("details-block")}
                  />
                )}
              </Desktop>
              <Mobile>
                <div className={bem("details-block")}>
                  <div className={bem("details-orders")}>
                    {appointment.appointment_services.map(
                      (appointmentService, i) =>
                        getPersonOrder(appointmentService, user, i + 1)
                    )}
                  </div>
                  <WavyLine />
                  {errorMsg && (
                    <Toast
                      msg={errorMsg}
                      onClose={() => setErrorMsg("")}
                      type="error"
                      showIcon
                      rounded
                    />
                  )}
                  <div className={bem("details-payment")}>
                    <div className={bem("options-container")}>
                      {getAvailablePaymentType(
                        appointment.Order?.tx_cancellation_policy
                          ?.late_cancellation_charge ||
                          appointment.location?.company
                            .late_cancellation_charge,
                        calculateAppointment
                      )}
                    </div>
                    <IconButton
                      className="flex-shrink-0"
                      iconName="info"
                      color={COLORS.PRIMARY}
                      onClick={() => setShowPointmentDetailsModal(true)}
                    />
                  </div>
                </div>
              </Mobile>
            </div>
            {!isRescheduling && (
              <div className={bem("details-buttons")}>
                {showRefundPolicyButton && (
                  <Button
                    className="!bg-surface"
                    color={COLORS.PRIMARY}
                    variant={VARIANTS.DEFAULT}
                    text={translations.refundAndCancellationPolicy}
                    iconRightName="arrow_right"
                    size="sm"
                    onClick={() => {
                      setShowTextModal(true);
                      setTextModalData({
                        title: translations.refundAndCancellation,
                        text: getRefundAndCancellationHtml(
                          appointment.Order?.tx_cancellation_policy
                            ?.late_cancellation_hours ||
                            appointment.location.company
                              .late_cancellation_hours,
                          appointment.Order?.tx_cancellation_policy
                            ?.late_cancellation_charge ||
                            appointment.location.company
                              .late_cancellation_charge,
                          appointment.Order?.tx_cancellation_policy
                            ?.no_show_charge ||
                            appointment.location.company.no_show_charge,
                          appointment.Order?.tx_cancellation_policy
                            ?.payment_type ||
                            appointment.location.company.payment_type
                        )
                      });
                    }}
                  />
                )}
                {/*<Desktop>*/}
                {/*  /!*TODO: add onClick*!/*/}
                {/*  <Button*/}
                {/*    className="!bg-surface flexible flex-1"*/}
                {/*    color={COLORS.BACKGROUND}*/}
                {/*    variant={VARIANTS.FILLED}*/}
                {/*    iconRightName="download"*/}
                {/*    size="sm"*/}
                {/*    text="Get receipt"*/}
                {/*  />*/}
                {/*</Desktop>*/}
              </div>
            )}
          </div>
        </TwoColsLayout>
      </div>
      <div className={bem("bottom-controls")}>
        {getButtons(
          appointment,
          () => setIsRescheduling(true),
          () => setShowAddToCalendarModal(true),
          isAllServicesIncluded
            ? null
            : () =>
                setModalError({
                  type: "Warning",
                  text: "Some services from past appointment not available"
                })
        )}
      </div>
      <FormattedTextModal
        show={showTextModal}
        onHide={() => setShowTextModal(false)}
        title={textModalData?.title}
        text={textModalData?.text || ""}
        BottomControls={
          getAppointmentStatus(appointment) === "upcoming"
            ? CancelButton
            : undefined
        }
      />
      <PointmentDetailsModal
        appointment={appointment as unknown as TAppointment}
        calculateAppointment={calculateAppointment}
        show={showPointmentDetailsModal}
        onHide={() => setShowPointmentDetailsModal(false)}
      />
      {cancelModalAppointmentId && (
        <CancelConfirmationModal
          show={Boolean(cancelModalAppointmentId)}
          onHide={() => setCancelModalAppointmentId(null)}
          onConfirm={onCancel}
          onReject={() => setCancelModalAppointmentId(null)}
          title="Cancellation Confirmation"
          cyId="cancel-appointment-confirmation-modal"
          cancelModalAppointmentId={cancelModalAppointmentId}
          setCancelModalAppointmentId={setCancelModalAppointmentId}
        />
      )}
      <AddToCalendarModal
        show={showAddToCalendarModal}
        onHide={() => setShowAddToCalendarModal(false)}
        event={{
          name: `Upcoming Pointment in ${appointment.location.name}`,
          location:
            appointment.location.address_display ||
            `${appointment.location.address_latitude}, ${appointment.location.address_longitude}`,
          description: `${peopleCount} ${
            peopleCount > 1 ? "people" : "person"
          } • ${appointment.appointment_services.length} service${
            appointment.appointment_services.length > 1 ? "s" : ""
          }`,
          startDate: getDate(
            appointment.start_time,
            appointment.location.timezone
          ),
          endDate: getDate(appointment.end_time, appointment.location.timezone),
          startTime: getFormattedTime(
            appointment?.start_time,
            "HH:mm",
            appointment.location.timezone
          ),
          endTime: getFormattedTime(
            appointment?.end_time,
            "HH:mm",
            appointment.location.timezone
          ),
          timeZone: appointment.location.timezone || "currentBrowser"
        }}
      />
      <Modal
        show={!!modalError}
        onHide={() => {
          if (modalError?.type !== "Warning") {
            setIsRescheduling(false);
          }
          setModalError(null);
        }}
        title={modalError?.type}
        disableHeader={false}
        disableBackground={false}
        align="bottom"
        BottomControls={BottomControls}
      >
        <div className="text-center p-4 font-inter text-body-lg text-on-background">
          {modalError?.text}
        </div>
      </Modal>
    </>
  );
};

export default BookingDetails;
