/* eslint-disable max-len */
import "./PaymentSection.scss";
import { useEffect, useState } from "react";
import { Button } from "elements/button/Button";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { bemElement, bemModifier } from "utils/bem-class-names";
import {
  TBookAppointmentResponse,
  TCalculateAppointment,
  TCompany
} from "types";
import FormattedTextModal from "components/modals/formatted-text-modal/FormattedTextModal";
import { useCart } from "providers/cart-provider";
import { joinClassNames } from "utils/join-class-names";
import { ICreditCardInputValue } from "elements/credit-card-input/CreditCardInput";
import { CreditCard } from "elements/credit-card/CreditCard";
import { usePayment } from "hooks/use-payment";
import { useAxios } from "providers/axios";
import { Toast } from "elements/toast/Toast";
import { Desktop, Mobile } from "components/responsive/Responsive";
import { PAYMENT_TYPES } from "models/payment-types";
import { useNavigate } from "react-router-dom";
import { StripePaymentMethodFragment } from "hooks/use-payment/PaymentMethodsSection.generated";
import { APP_ROUTES } from "routes";
import { useUser } from "../../../providers/user";
import BookingConfirmationModal from "../../../components/modals/booking-confirmation-modal/BookingConfirmationModal";
import {
  getDate,
  getFormattedTime,
  getRelativeDateTime
} from "../../../utils/date-time";
import { usePeopleCount } from "../../../hooks/use-people-count";
import { AddToCalendarModal } from "../../../components/modals/add-to-calendar-modal/AddToCalendarModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CARD_ELEMENT_OPTIONS } from "stripeConfig";
import { getRawPhoneNumber } from "../../../utils/getRawPhoneNumber";

/* eslint-disable no-console */
const baseClassName = "payment-section";
const bem = bemElement(baseClassName);
const defaultDescription =
  "Make sure that all your Reservation details are correct and complete the payment.";

export interface IPaymentSectionProps {
  description?: string;
}

const getRefundAndCancellationText = (company?: TCompany) => {
  if (!company) {
    return "";
  }

  switch (company.payment_type) {
    case PAYMENT_TYPES.PAY_FULL_UPFRONT:
      return `
    <h3 style="margin-bottom: 8px; color: #302F2E">Full Prepay</h3>
    <div>
      Take note! This salon requires full payment of the service total at the time of booking. Your card will be charged shortly. Change of plans? Just cancel or reschedule your Pointment at least ${company.late_cancellation_hours} hours before the start time in order to keep your salon credit. Failure to cancel within the allotted time frame will result in a loss of salon credit. 
    </div>`;
    case PAYMENT_TYPES.PARTIAL_PAY_UPFRONT:
      return `
    <h3 style="margin-bottom: 8px; color: #302F2E">Partial Prepay / Deposit</h3>
    <div>
      Take note! This salon requires a deposit of ${parseFloat(
        ((company?.late_cancellation_charge || 0) * 100).toFixed(2)
      )}% of the service total at the time of booking. Your card will be charged shortly. Change of plans? Just cancel or reschedule your Pointment at least ${
        company.late_cancellation_hours
      } hours prior to the start time in order to get your money back or earn salon credit. Failure to cancel within the allotted time frame will result in a loss of the full deposit. You will be charged for the remainder of your booked service(s), as well as any a la carte add-ons, upon completion of your Pointment. 
    </div>`;
    case PAYMENT_TYPES.PAY_IN_STORE:
      return `
    <h3 style="margin-bottom: 8px; color: #302F2E">Pay in Store Option </h3>
    <div>
      Change of plans? Be sure to cancel your Pointment at least ${
        company.late_cancellation_hours
      } hours before the start time in order to avoid a cancellation fee. According to this salon's policy, late cancellations will be charged ${parseFloat(
        (company.late_cancellation_charge * 100).toFixed(2)
      )}% of the service total, and no shows will be charged ${parseFloat(
        (company.no_show_charge * 100).toFixed(2)
      )}%. 
    </div>`;
    default:
      return "";
  }
};

const getAvailablePaymentType = (
  company?: TCompany,
  calculateAppointment?: TCalculateAppointment | null
) => {
  if (!calculateAppointment || !company) {
    return null;
  }

  let paymentTypeData = {
    title: "Pay in full",
    description: `${
      calculateAppointment.amount_paid_now_with_refunds > 0
        ? `$${calculateAppointment.amount_paid_now_with_refunds.toFixed(
            2
          )} in credits has been applied.`
        : ""
    } Payment of $${calculateAppointment.amount_paid_with_payment_method.toFixed(
      2
    )} is required to book.`,
    iconName: "other_full_payment",
    iconPosition: "middle"
  };

  switch (calculateAppointment.payment_type) {
    case PAYMENT_TYPES.PARTIAL_PAY_UPFRONT:
      paymentTypeData = {
        title: "Partial pay upfront",
        description: `${
          calculateAppointment.amount_paid_now_with_refunds > 0
            ? `$${calculateAppointment.amount_paid_now_with_refunds.toFixed(
                2
              )} in credits has been applied.`
            : ""
        } ${
          calculateAppointment.amount_paid_with_payment_method > 0
            ? `Partial payment of $${calculateAppointment.amount_paid_with_payment_method.toFixed(
                2
              )} is required to book.`
            : ""
        } Final payment of $${calculateAppointment.amount_paid_at_appointment.toFixed(
          2
        )} at the time of your appointment by: Cash, Credit Card, or Apple Pay.`,
        iconName: "other_partial_payment",
        iconPosition: "start"
      };
      break;
    case PAYMENT_TYPES.PAY_IN_STORE:
      paymentTypeData = {
        title: "Pay in store",
        description: `Final payment of $${calculateAppointment.total_price?.toFixed(
          2
        )} at the time of your appointment by: Cash, Credit Card, or Apple Pay. ${
          company.late_cancellation_charge > 0
            ? `Late cancellation fee of $${(
                company.late_cancellation_charge *
                (calculateAppointment.total_price || 0)
              ).toFixed(2)} would apply.`
            : ""
        }`,
        iconName: "other_store",
        iconPosition: "start"
      };
      break;
  }

  return (
    <div
      className={bemModifier(bem("payment-type"), paymentTypeData.iconPosition)}
    >
      <div className={bem("payment-type-left")}>
        <SvgIcon name={paymentTypeData.iconName} />
      </div>
      <div className={bem("payment-type-right")}>
        <span>{paymentTypeData.title}</span>
        <span>{paymentTypeData.description}</span>
      </div>
    </div>
  );
};

export const PaymentSection = ({
  description = defaultDescription
}: IPaymentSectionProps) => {
  const {
    cart,
    bookedAppointmentResponse,
    getBookAppointmentBody,
    setBookedAppointmentResponse,
    resetEverything
  } = useCart();
  const { cards, addCard } = usePayment();
  const navigate = useNavigate();
  const { api } = useAxios();
  const { user } = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [textModalData, setTextModalData] = useState<{
    title: string;
    text: string;
  }>();
  const [addNewCardField, setAddNewCardField] = useState<boolean>(false);
  const [newCard, setNewCard] = useState<ICreditCardInputValue | undefined>(
    undefined
  );
  const [payingLoading, setPayingLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [disabledOrderButton, setDisabledOrderButton] =
    useState<boolean>(false);
  const [calculateAppointment, setCalculateAppointment] =
    useState<TCalculateAppointment | null>(null);
  const [showAddToCalendarModal, setShowAddToCalendarModal] =
    useState<boolean>(false);
  const peopleCount = usePeopleCount(
    bookedAppointmentResponse?.createdAppointment
  );

  const selectPaymentMethodId = (id: string) => {
    setPaymentMethodId(id);
    setAddNewCardField(false);
    setNewCard(undefined);
  };

  const onAddCardClick = () => {
    setAddNewCardField(true);
    setPaymentMethodId(null);
  };

  const handlePayButtonClick = async (event: any) => {
    event.preventDefault();
    setPayingLoading(true);
    if (paymentMethodId) {
      bookAppointment(paymentMethodId);
    } else if (addNewCardField) {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name:
              user?.impersonate_name ||
              `${user?.first_name} ${user?.last_name}`,
            email: user?.impersonate_name ? "" : user?.email,
            phone: user?.impersonate_name
              ? ""
              : getRawPhoneNumber(user?.phone_number || "") || undefined
          }
        });

        if (error) {
          setErrorMsg(error?.message || "");
          setPayingLoading(false);
        } else {
          try {
            const newPaymentMethod = await addCard({
              paymentMethod: {
                id: paymentMethod?.id,
                brand: paymentMethod?.card?.brand,
                exp_month: paymentMethod?.card?.exp_month,
                exp_year: paymentMethod?.card?.exp_year,
                last4: paymentMethod?.card?.last4
              },
              is_default: cards.length === 0
            });
            setPaymentMethodId(
              JSON.parse(newPaymentMethod?.data?.object_json)?.id
            );

            if (newPaymentMethod?.data?.stripe_id) {
              bookAppointment(newPaymentMethod.data.stripe_id);
            }
          } catch (e) {
            setErrorMsg(e?.response?.data?.message || e || "");
            setPayingLoading(false);
          }
        }
      }
    } else if (
      user?.impersonate_name &&
      calculateAppointment?.payment_type === PAYMENT_TYPES.PAY_IN_STORE
    ) {
      bookAppointment(PAYMENT_TYPES.PAY_IN_STORE);
    } else {
      // TODO: add message for empty cards list
      setErrorMsg("Please select payment method or add new card");
      setPayingLoading(false);
    }
  };

  const bookAppointment = async (id: string) => {
    (async function () {
      try {
        const response: TBookAppointmentResponse = (await api
          .post(`/v1/book-appointment/`, {
            ...getBookAppointmentBody(),
            impersonate_id: user?.impersonate_name
              ? user?.impersonate_id
              : undefined,
            payment_method_id: id
          })
          .then((r) => r.data)) as TBookAppointmentResponse;

        setBookedAppointmentResponse(response);
        setIsPaymentSuccess(true);
      } catch (err) {
        setErrorMsg(err.response.data.message);
      } finally {
        setPayingLoading(false);
      }
    })();
  };

  useEffect(() => {
    if ((window as any).ApplePaySession) {
      console.log("Apple Pay is supported in this browser");
      // setPaymentMethods([applePay, mockDefaultCard]);
    } else {
      // setPaymentMethods([mockDefaultCard, mockDefaultCard1]);
    }
  }, []);

  useEffect(() => {
    if (cards && !newCard) {
      setPaymentMethodId(
        cards.find((card: StripePaymentMethodFragment) => card.is_default)
          ?.object_json?.id
      );
    }
  }, [cards, newCard]);

  useEffect(() => {
    (async function () {
      try {
        const response: TCalculateAppointment = (await api
          .post(`/v1/calculate-appointment/`, {
            ...getBookAppointmentBody(),
            impersonate_id: user?.impersonate_name
              ? user?.impersonate_id
              : undefined
          })
          .then((r) => r.data)) as TCalculateAppointment;

        setCalculateAppointment(response);

        if (!response.have_stripe_account) {
          setErrorMsg(
            "This salon is not accepting online payments at this time. Please contact the salon directly to book your appointment."
          );
          setDisabledOrderButton(true);
        }
      } catch (err) {
        setErrorMsg(err.response.data.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBookAppointmentBody]);

  const onLogout = async () => {
    try {
      await api.post("v1/users/impersonate-logout");

      const adminUrl = process.env.REACT_APP_ADMIN_URL;
      window.location.href = `${adminUrl}/dashboard/Customer/${user?.impersonate_id}/show`;
    } catch (error) {
      throw new Error("Error logging out: " + error.message);
    }
  };

  return (
    <div className={baseClassName}>
      <Mobile>
        {errorMsg && (
          <Toast
            msg={errorMsg}
            onClose={() => setErrorMsg("")}
            type="error"
            showIcon
          />
        )}
      </Mobile>

      <div className={bem("content")}>
        <h2 className={bem("title")}>Reservation</h2>
        <div className={bem("message")}>{description}</div>

        <div className={bem("category-type")}>
          <span className={bem("category-title")}>
            Available payment method
          </span>
          <div className={bem("options-container")}>
            {getAvailablePaymentType(
              cart.location?.company,
              calculateAppointment
            )}
          </div>
          <span
            className={bem("cancellation-policy")}
            aria-hidden="true"
            onClick={() => {
              setShowTextModal(true);
              setTextModalData({
                title: "Refund & Cancellation",
                text: getRefundAndCancellationText(cart.location?.company)
              });
            }}
          >
            Cancellation & No-Show Policy Details
          </span>
        </div>

        <div className={bem("category-type")}>
          <span className={bem("category-title")}>Pay with:</span>
          <div
            className={joinClassNames(
              bem("options-container"),
              bem("payment-methods")
            )}
          >
            {cards.map((card: StripePaymentMethodFragment) => (
              <CreditCard
                key={card.id}
                card={card}
                idDefault={card.is_default}
                onClick={() => selectPaymentMethodId(card.object_json.id)}
                checked={paymentMethodId === card.object_json.id}
                cyId="saved-credit-card"
              />
            ))}
          </div>
          <div className={bem("new-card-container")}>
            {addNewCardField ? (
              <form id="addNewCardForm" onSubmit={handlePayButtonClick}>
                <CardElement
                  onChange={(e) => {
                    if (e.complete) {
                      setErrorMsg("");
                    }
                  }}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </form>
            ) : (
              <Button
                className={joinClassNames("flexible", bem("add-card-btn"))}
                onClick={onAddCardClick}
                color={COLORS.SECONDARY}
                variant={VARIANTS.OUTLINED}
                iconLeftName="plus"
                cyId="add-credit-card-button"
                text="Add credit card"
              />
            )}
          </div>
        </div>
        {/* <WavyLine className={bem('wavy-line')} /> */}
        <div className={bem("pay-btn-container")}>
          <Button
            type="submit"
            form="addNewCardForm"
            onClick={handlePayButtonClick}
            className={joinClassNames("flexible", bem("pay-btn"))}
            color={COLORS.SECONDARY}
            variant={VARIANTS.FILLED}
            disabled={disabledOrderButton || payingLoading}
            text={
              payingLoading
                ? "Processing payment..."
                : cart.location?.company?.payment_type === "PAY_IN_STORE"
                ? "Book Now"
                : `Pay $${
                    calculateAppointment?.amount_paid_with_payment_method.toFixed(
                      2
                    ) || ""
                  }`
            }
            submitting={payingLoading}
            cyId="pay-button-desktop"
          />
        </div>
        <Desktop>
          {errorMsg && (
            <Toast
              msg={errorMsg}
              onClose={() => setErrorMsg("")}
              type="error"
              showIcon
              rounded
            />
          )}
        </Desktop>
      </div>

      <FormattedTextModal
        show={showTextModal}
        onHide={() => setShowTextModal(false)}
        title={textModalData?.title}
        text={textModalData?.text || ""}
      />
      <BookingConfirmationModal
        show={
          isPaymentSuccess &&
          !!bookedAppointmentResponse?.createdAppointment &&
          !showAddToCalendarModal
        }
        onHide={async () => {
          if (!showAddToCalendarModal) {
            if (user?.impersonate_name) {
              await onLogout();
            } else {
              navigate(APP_ROUTES.ME);
            }
            resetEverything();
          }
        }}
        onViewDetailsClick={resetEverything}
        onAddToCalendarClick={() => setShowAddToCalendarModal(true)}
        appointment={{
          id: bookedAppointmentResponse?.createdAppointment?.id,
          address:
            bookedAppointmentResponse?.createdAppointment?.location
              ?.address_display || "",
          preview:
            bookedAppointmentResponse?.createdAppointment?.location
              ?.cover_photo_url || "",
          slots: [
            {
              available: true,
              time: bookedAppointmentResponse?.createdAppointment?.start_time
                ? getRelativeDateTime(
                    bookedAppointmentResponse?.createdAppointment?.start_time ||
                      "",
                    bookedAppointmentResponse?.createdAppointment?.location
                      ?.timezone
                  )
                : ""
            }
          ],
          title:
            bookedAppointmentResponse?.createdAppointment?.location?.name || "",
          peopleCount,
          servicesCount:
            bookedAppointmentResponse?.createdAppointment?.appointment_services
              ?.length,
          slotType: "primary"
        }}
      />
      {bookedAppointmentResponse?.createdAppointment && (
        // TODO: add timezone
        <AddToCalendarModal
          show={showAddToCalendarModal}
          onHide={() => setShowAddToCalendarModal(false)}
          event={{
            name: `Upcoming Pointment in ${bookedAppointmentResponse?.createdAppointment?.location?.name}`,
            location:
              bookedAppointmentResponse?.createdAppointment?.location
                ?.address_display ||
              `${bookedAppointmentResponse?.createdAppointment?.location?.address_latitude}, ${bookedAppointmentResponse?.createdAppointment?.location?.address_longitude}`,
            description: `${peopleCount} ${
              peopleCount > 1 ? "people" : "person"
            } • ${
              bookedAppointmentResponse?.createdAppointment
                ?.appointment_services?.length
            } service${
              bookedAppointmentResponse?.createdAppointment
                ?.appointment_services &&
              bookedAppointmentResponse?.createdAppointment
                ?.appointment_services?.length > 1
                ? "s"
                : ""
            }`,
            startDate: getDate(
              bookedAppointmentResponse?.createdAppointment?.start_time || "",
              bookedAppointmentResponse?.createdAppointment?.location?.timezone
            ),
            endDate: getDate(
              bookedAppointmentResponse?.createdAppointment?.end_time?.toString() ||
                "",
              bookedAppointmentResponse?.createdAppointment?.location?.timezone
            ),
            startTime: getFormattedTime(
              bookedAppointmentResponse?.createdAppointment?.start_time || "",
              "HH:mm",
              bookedAppointmentResponse?.createdAppointment?.location?.timezone
            ),
            endTime: getFormattedTime(
              bookedAppointmentResponse?.createdAppointment?.end_time?.toString() ||
                "",
              "HH:mm",
              bookedAppointmentResponse?.createdAppointment?.location?.timezone
            ),
            timeZone:
              bookedAppointmentResponse?.createdAppointment?.location
                ?.timezone || "currentBrowser"
          }}
        />
      )}
    </div>
  );
};

export default PaymentSection;
